import {CollectionViewer, DataSource, SelectionChange} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {BehaviorSubject, merge, Observable, zip} from 'rxjs';
import {map} from 'rxjs/operators';
import {CatalogService} from '../../_services/catalog.service';
import {Section} from '../../shared/model/section.model';
import {Planche} from '../../shared/model/planche.model';
import {TreeNode} from './treenode.class';

/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
export class DynamicDataSource implements DataSource<TreeNode> {

  dataChange = new BehaviorSubject<TreeNode[]>([]);

  get data(): TreeNode[] { return this.dataChange.value; }
  set data(value: TreeNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  nodeCount: number;

  constructor(private _treeControl: FlatTreeControl<TreeNode>,
              private catalogid: string,
              private section: Section,
              private catalogService: CatalogService) {
    this.data = this.section.children.map(planche => new TreeNode(planche, 0, true));
  }

  connect(collectionViewer: CollectionViewer): Observable<TreeNode[]> {

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {}

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: TreeNode, expand: boolean , cbk = null) {
      if (!node.expanded ) {
        this.expand(node, cbk);
      } else {
        this.collapse(node, cbk);
      }
  }

  expand(node: TreeNode , cbk = null) {
    node.isLoading = true;
    const index = this.data.indexOf(node);
    this.catalogService.getPlancheDetails(this.catalogid, node.item._id).subscribe((planche: Planche) => {
      const nodes = planche.calques.map(calque => {
        // calque.graphic = node.item.graphic;
        return new TreeNode(calque, node.level + 1, false, node);
      });
      this.data.splice(index + 1, 0, ...nodes);
      this.dataChange.next(this.data);
      node.expanded  = true;
      node.highlightedChildrenIndexes.forEach((i) => {
        if (nodes[i]) {
          nodes[i].item.highlighted = true;
        }
      });
      node.isLoading = false;
      if (cbk) {return cbk(nodes); }
    });
  }

  collapse(node: TreeNode , cbk = null) {
    const index = this.data.indexOf(node);
    let count = 0;
    for (let i = index + 1; i < this.data.length
    && this.data[i].level > node.level; i++, count++) {}
    this.data.splice(index + 1, count);
    this.dataChange.next(this.data);
    node.expanded  = false;
    node.isLoading = false;
    if (cbk) {return cbk(); }
  }

  collapseAll() {
    this.data.forEach((n) => {
      if (n.expanded) {
        this.collapse(n);
      }
    });
  }

  expandAll(cbk) {
    const next = (i) => {
      if (i >= this.data.length) {
        return cbk();
      }
      if (this.data[i].expanded || this.data[i].hidden || !this.data[i].expandable) {
        next(i + 1);
        return;
      }
      this.expand(this.data[i], () => {
        next(i + 1);
      });

    };
    next(0);
  }

  findTreeNodeByPlancheIdAndCalqueRef(plancheId , calqueRef , cbk) {

    const next = () => {
      if (calqueRef) {
        const calqueNode = this.data.find((node) => {
          return node.item.ref === calqueRef;
        });
        if (calqueNode) {
          cbk(calqueNode);
        } else {
          cbk(plancheNode);
        }
      }
    };

    const plancheNode = this.data.find((node) => {
      return node.item._id === plancheId;
    });
    if (plancheNode) {
      if (!plancheNode.expanded) {
        this.expand(plancheNode, () => {
          next();
        });
      } else {
        next();
      }
    }
  }

  reset(cbk) {
    this.nodeCount = null;
    const next = (i) => {
      if (i >= this.data.length) {
        return cbk();
      }
      this.data[i].hidden = false;
      if (this.data[i].item && this.data[i].item.highlighted) {
        this.data[i].item.highlighted = false;
      }
      if (this.data[i].parent === null) {
        this.data[i].highlightedChildrenIndexes = [];
      }
      this.collapse(this.data[i], () => {
        next(i + 1);
      });
    };
    next(0);
  }

  searchByPlanche(keyword, cbk) {
    let nodeCount = 0;
    const observables = [];
    this.data.forEach((n) => {
      if (n.level !== 0) { return; }
      n.highlightedChildrenIndexes = [];
      n.isLoading = true;
      observables.push(this.catalogService.findCalquesByKeyword(n.item._id, keyword));
    });
    const allObs = zip(... observables);
    let firstNode = null;
    allObs.subscribe((res: any) => {
      const next = (i) => {
        if (i >= res.length) {
          this.nodeCount = nodeCount;
          return cbk(firstNode);
        }
        const plancheNode = this.data.find((node) => {
          return node.item._id === res[i]._id;
        });
        if (!plancheNode) {
          return next(i + 1);
        }
        if (res[i].indexes.length ===  0) {
          plancheNode.isLoading = false;
          plancheNode.hidden = true;
          return next(i + 1);
        }
        plancheNode.isLoading = false;
        nodeCount = nodeCount + res[i].indexes.length;
        plancheNode.highlightedChildrenIndexes = res[i].indexes;
        if (!firstNode) {
          firstNode = plancheNode;
        }
        this.expand(plancheNode, (nodes) => {
          res[i].indexes.forEach((j) => {
            if (nodes[j]) {
              nodes[j].item.highlighted = true;
            }
          });
          next(i + 1);
        });
      };
      next(0);
    });
  }

  findTreeNodesByKeyword(catalogid: string, keyword: string, sectionIndex: number, cbk) {
    this.catalogService.findPlanchesByKeyword(catalogid, keyword).subscribe((e: Array<any>) => {
      let results = 0;
      e.forEach((r) => {
        if (r.section === sectionIndex) {
          results++;
        }
      });
      this.nodeCount = results;
      const foundPlanches = this.data.filter((node) => {
        this.collapse(node);
        node.hidden = true;
        return e.find((p) => {
         return node.item._id === p.planche;
        });
      });
      foundPlanches.forEach((p) => {
        p.hidden = false;
        this.expand(p, (nodes) => {
          const filteredNodes = nodes.filter((cn) => {
            return e.find((r) => {
              return r.ref === cn.item.ref
                || (cn.item.variantes && cn.item.variantes.find((v) => v.ref === r.ref));
            });
          });
          filteredNodes.forEach((fn) => {
            fn.item.highlighted = true;
          });
        });
      });
      if (foundPlanches.length > 0) {
        return cbk(null, foundPlanches[0]);
      }
      cbk();
    }, (err) => {
      cbk(err);
    });

  }
}

import { Component, OnInit } from '@angular/core';
import { GtmService } from 'src/app/_services/gtm.service';
@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.css']
})
export class CookieBannerComponent implements OnInit {
  public showBanner: boolean = true;

  constructor(private gtmService: GtmService) { }

  ngOnInit(): void {
    if (this.hasConsented()) this.gtmService.injectGtmScript();
    this.showBanner = !localStorage.getItem('cookieConsent');
  }

  acceptCookies(): void {
    this.setConsent(true);
    this.showBanner = false;
    this.gtmService.injectGtmScript();
  }

  declineCookies(): void {
    this.setConsent(false);
    this.showBanner = false;
  }

  private hasConsented(): boolean {
    return localStorage.getItem('cookieConsent') === 'true';
  }

  private setConsent(consent: boolean): void {
    localStorage.setItem('cookieConsent', String(consent));
  }
}
import {NgModule} from '@angular/core';
import {NavigationEnd, Router, RouterModule, Routes} from '@angular/router';
import {CatalogsComponent} from './catalogs/catalogs.component';
import {ViewerComponent} from './viewer/viewer.component';
import {InitAccountComponent} from './init-account/init-account.component';
import {ViewerCartComponent} from './viewer/viewer-cart/viewer-cart.component';
import {ViewerWishlistComponent} from './viewer/viewer-wishlist/viewer-wishlist.component';
import {TermsComponent} from './legal/terms/terms.component';
import {CookieComponent} from './legal/cookie/cookie.component';
import {PrivacyComponent} from './legal/privacy/privacy.component';
import { environment } from 'src/environments/environment';
import { GtmService } from './_services/gtm.service';

declare let gtag: Function;

const routes: Routes = [
  {path: '', component: CatalogsComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'cookies', component: CookieComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'printcart', component: ViewerCartComponent, data: {print: true}},
  {path: 'printwishlist', component: ViewerWishlistComponent, data: {print: true}},
  {path: 'catalog/:id', component: ViewerComponent},
  {path: 'initaccount', component: InitAccountComponent},
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {
  constructor(private router: Router, private gtmService: GtmService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.gtmService.isInjected()) {
        gtag('event', 'page_view', {
        'page_path': event.urlAfterRedirects,
        });
      }
    });
  }
}

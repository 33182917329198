import {Component, OnInit, Optional} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {DialogService} from '../../_services/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {CartService} from '../../_services/cart.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-viewer-wishlist',
  templateUrl: './viewer-wishlist.component.html',
  styleUrls: ['./viewer-wishlist.component.css']
})
export class ViewerWishlistComponent implements OnInit {
  config: any = {};

  wishlist: any;

  constructor(private dialogService: DialogService,
              private cartService: CartService,
              private translateService: TranslateService,
              private route: ActivatedRoute,
              @Optional() public dialogRef?: MatDialogRef<ViewerWishlistComponent>) {
  }

  ngOnInit() {
    this.config = this.route.snapshot.data;
    this.wishlist = this.cartService.getWishList();

    if (this.config.print) {
      window.print();
      return;
    }
  }

  removeItem(i) {
    this.cartService.removeWishListItem(i);
  }

  goToArticle(item) {
    if (this.dialogRef) {
      this.dialogRef.close(item);
    }
  }


}

import { Pipe, PipeTransform } from '@angular/core';
/*
 * Transform Timestamp to Date
 * Usage:
 *   value | timestampToDate
 * Example:
 *   {{ new Date('September 17, 2018 11:31:00') | timestampToDate }}
 *   formats to: 1537176660000
*/
@Pipe({name: 'timestampToDate'})
export class TimestampToDatePipe implements PipeTransform {
  transform(value: number): Date {
    return new Date(value);
  }
}

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';

import {DialogComponent} from '../shared/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) {
  }

  modal(title, content, color, confirm?) {
    //noinspection TypeScriptUnresolvedFunction
    const id = color === 'warn' ? 'custom-dialog-warn' : (color === 'error' ? 'custom-dialog-error' : null);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '600px',
      id: id,
      data: {
        title: title,
        content: content,
        color: color,
        confirm: confirm
      }
    });
    return dialogRef;
  }

  error(err) {
    //noinspection TypeScriptUnresolvedFunction
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '600px',
      data: {
        title: 'Erreur',
        content: '<strong>' + err.message + '</strong>',
        color: 'error'
      }
    });
    return dialogRef.afterClosed();
  }
}

import {Component, OnInit, Input} from '@angular/core';
import {Subject} from 'rxjs';
import {CatalogService} from '../../_services/catalog.service';
import {Item} from '../../shared/model/item.model';
import {environment} from '../../../environments/environment';

declare const $;


declare const panzoom;

@Component({
  selector: 'app-viewer-graph',
  templateUrl: './viewer-graph.component.html',
  styleUrls: ['./viewer-graph.component.css']
})
export class ViewerGraphComponent implements OnInit {

  @Input() catalog: any;

  @Input() viewerSubject: Subject<Item>;

  selectedItem: any;

  currentGraphId: string;

  loading = false;

  svgobject = '<a href="#"></a>';

  root = true;

  currentSection = 0;

  environment = environment;

  constructor(private catalogService: CatalogService) {
  }

  ngOnInit() {
    this.viewerSubject.asObservable().subscribe((item) => {
      if (item.root) {
        return this.loadRoot(item.section);
      }
      this.root = false;
      $('svg:first g.defaultclass.selected').removeClass('selected');
      this.selectedItem = item;
      if (this.currentGraphId !== item.plancheId) {
        this.loadGraphic(item.plancheId, () => {
          if (item.calque) {
            $('svg #' + item.index).addClass('selected');
          }
        });
      } else {
        if (item.calque) {
          $('svg #' + item.index).addClass('selected');
        }
      }
      this.currentGraphId = item.plancheId;
    });
  }

  loadRoot(sectionNumber: number = this.currentSection) {
    this.currentSection = sectionNumber;
    this.currentGraphId = null;
    this.root = true;
  }

  selectPlanche(plancheid) {
    this.viewerSubject.next({plancheId : plancheid, fromGraph: true});
  }

  loadGraphic(graphId, cbk) {
    this.loading = true;
    this.catalogService.getCatalogGraphic(this.catalog.id, graphId).subscribe((graph) => {
      $('#svgwrapper').html(graph);
      const svg = $($('#svgwrapper').find('svg')[0]);
      const fil2Class =  svg.find('.fil2').css('fill');
      if (fil2Class === 'none') {
        svg.find('.fil2').addClass('fill-none');
      }
      svg.find(' > g').not('#Neutre').not('#rep').each((i , element) => {
        svg.find('#' + i).removeAttr('id');
        $(element).addClass('defaultclass').attr('id' , i).click(() => {
          this.viewerSubject.next({plancheId : graphId, index: i.toString(), fromGraph: true});
        });
      });
      this.loading = false;
      cbk();
    });
  }

  backToRoot() {
    this.viewerSubject.next({root: true, fromGraph: true});
  }

}

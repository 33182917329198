import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../_services/login.service';

@Component({
  selector: 'app-init-account',
  templateUrl: './init-account.component.html',
  styleUrls: ['./init-account.component.css']
})
export class InitAccountComponent implements OnInit {

  tokenid: string;

  customer: any ;

  password: string;

  password_confirm: string;

  constructor(private route: ActivatedRoute , private router: Router , private loginService: LoginService ) { }

  ngOnInit() {
    const token = this.route.snapshot.queryParamMap.get('customertoken');
    if (!token) {
      this.router.navigateByUrl('/');
    }
    this.loginService.getCustomerToken(token).subscribe((token) => {
      this.tokenid = token.id;
      this.customer = token.customer;
    } , () => {
      this.router.navigateByUrl('/');
    });

  }

  send() {
    this.loginService.initPassword(this.tokenid, {password : this.password , password_confirm : this.password_confirm}).subscribe(() => {
      this.router.navigateByUrl('/');
    });

  }

}

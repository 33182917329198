import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {CatalogService} from './catalog.service';
import {LoginService} from './login.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cart: any;
  invoiceDetails: any;
  wishlist: any;

  get cartLength(): number {
    return this.cart.filter((e) => {
      return e.article.hasOwnProperty('prix') && e.article.prix != null;
    }).length;
  }


  constructor(private catalogService: CatalogService, private loginService: LoginService) {
    environment.shop = {shop_tva: 19.6};

    this.cart = localStorage.getItem('cart');
    this.cart = this.cart ? JSON.parse(this.cart) : [];

    this.wishlist = localStorage.getItem('wishlist');
    this.wishlist = this.wishlist ? JSON.parse(this.wishlist) : [];

    this.loginService.loginSubject.subscribe((connected) => {
      if (connected) {
        this.refreshCart(() => {
        });
      } else {
        this.cleanCart();
        this.cleanWishList();
      }
    });

    this.invoiceDetails = {
      total: 0,
      promo: 0,
      remise: 0,
      vat: 0,
      ttc: 0
    };

    this.updateInvoiceDetails();
  }

  getCart() {
    return this.cart;
  }

  getWishList() {
    return this.wishlist;
  }

  cleanWishList() {
    this.wishlist = [];
    localStorage.removeItem('wishlist');
  }

  updateInvoiceDetails() {
    const details = {
      total: 0,
      promo: 0,
      remise: 0,
      vat: 0,
      ttc: 0
    };
    this.cart.forEach((item) => {
      let tmpTotal = 0;
      let tmpPromo = 0;
      let tmpRemise = 0;

      if (!item.article.hasOwnProperty('prix')) {
        return;
      }

      // 1 - Calculate gross
      if (item.article.remise){
        tmpTotal = item.article.remise.prix_remise * item.count;
      }else{
        tmpTotal = item.article.prix * item.count;
      }


      // 2 - Calculate promo
      if (item.article.discounts) {
        let discountTotal = 0;
        let tmpThreshold = 0;
        item.article.discounts.forEach((discount) => {
          if (discount.threshold === 0) {
            discount.threshold = 1;
          }
          if (discount.threshold <= item.count && discount.threshold > tmpThreshold) {
            const diffPrice = (item.article.prix || 0) - discount.coef;
            discountTotal = diffPrice * item.count;
            tmpThreshold = discount.threshold;
          }
        });
        tmpPromo = discountTotal;
      }


      // 4 - Add to details
      details.total += tmpTotal;
      details.promo += tmpPromo;
    });

    details.vat = (details.total - details.promo) * (environment.shop.shop_tva / 100);
    details.ttc = details.total - details.promo + details.vat;

    this.invoiceDetails.total = details.total;
    this.invoiceDetails.promo = details.promo;
    this.invoiceDetails.ttc = details.ttc;
    this.invoiceDetails.vat = details.vat;
  }

  getNumberOfArticle() {
    let res = 0;
    this.cart.forEach((item) => {
      res += parseInt(item.count, 10);
    });
    return res;
  }

  getNumberOfWishListArticle() {
    let res = 0;
    this.wishlist.forEach((item) => {
      res++;
    });
    return res;
  }

  addToCart(article) {
    let index = null;
    if (!article.stock) article.ref = article.ref2
    this.cart.find((cartitem, i) => {
      if (cartitem.article.ref === article.ref) {
        index = i;
        return true;
      }
    });
    if (index == null) {
      this.cart.push({article: article, count: parseInt(article.unite_service || 1, 10)});
    } else {
      this.cart[index].count = this.cart[index].count + parseInt(article.unite_service || 1, 10);
    }
    this.saveCart();
  }

  addToWishList(article) {
    const found = this.wishlist.find((e) => {
      return article.id === e.id;
    });
    if (!found) {
      this.wishlist.push(article);
      this.saveWishList();
    }
  }

  refreshCart(cb) {
    if (this.cart.length === 0) {
      this.saveCart();
      return cb();
    }
    const next = (i) => {
      if (i >= (this.cart.length)) {
        this.saveCart();
        return cb();
      }
      const item = this.cart[i];
      this.catalogService.getArticle(item.article.ref).map((res: any) => {
        res.label = this.cart[i].article.label;
        return res;
      }).subscribe((article) => {
        this.cart[i].article = article;
        next(i + 1);
      });
    };
    next(0);
  }

  removeItem(index) {
    this.cart.splice(index, 1);
    this.saveCart();
  }

  removeWishListItem(index) {
    this.wishlist.splice(index, 1);
    this.saveWishList();
  }

  removeFromWishList(itemRef) {
    let found = false;
    let index = 0;
    this.wishlist.find((e, i) => {
      if (itemRef === e.id) {
        found = true;
        index = i;
      }
    });
    if (found) {
      this.removeWishListItem(index);
    }
  }

  isItemFavorite(itemRef) {
    let found = false;
    this.wishlist.find((item) => {
      if (itemRef === item.id) {
        found = true;
      }
    });
    return found;
  }


  updateQuantity(index, count) {
    this.cart[index].count = parseInt(this.cart[index].count, 10);
    if (this.cart[index].count + parseInt(count, 10) <= 0) {
      this.removeItem(index);
    } else {
      this.cart[index].count += parseInt(count, 10);
      this.saveCart();
    }
  }

  saveCart() {
    this.updateInvoiceDetails();
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  saveWishList() {
    localStorage.setItem('wishlist', JSON.stringify(this.wishlist));
  }

  cleanCart() {
    this.cart.splice(0, this.cart.length);
    this.updateInvoiceDetails();
    localStorage.removeItem('cart');
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {ConfigService} from './_services/config.service';
import { GtmService } from './_services/gtm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private translate: TranslateService, private configService: ConfigService, private route: ActivatedRoute, private gtmService: GtmService) {
    this.route.queryParams.subscribe((params) => {
      environment.api.alpine.token = params.token;
    });
    this.configService.getShopConfig().subscribe((config) => {
      environment.shop = config;
    });
    // @ts-ignore
    let userLang = navigator.language || navigator.userLanguage;
    userLang = userLang.split('-')[0];
    translate.setDefaultLang('en');
    translate.use(userLang);
  }

  ngOnInit(): void {
  }

}

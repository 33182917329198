import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {CatalogService} from '../_services/catalog.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.css']
})
export class CatalogsComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer, private catalogService: CatalogService, public dialog: MatDialog, public router: Router) {
  }

  maintenance = false;

  type: string;

  ngOnInit() {

  }

  selectType(catalogType) {
    this.type = catalogType;
    this.catalogService.getAllCatalogs(catalogType).subscribe((catalogs) => {
      if (catalogs.length > 0) {
        //noinspection TypeScriptUnresolvedFunction
        const dialogRef = this.dialog.open(CatalogsDialog, {
          width: '600px',
          maxWidth: '100vw',
          data: catalogs,
          panelClass: 'custom-catalog-dialog',
          disableClose: true
        });

        dialogRef.afterClosed().subscribe(selectedId => {
          if (selectedId) {
            this.router.navigateByUrl('/catalog/' + selectedId);
          } else {
            this.type = undefined;
          }
        });
      } else {
        this.maintenance = true;
      }
    }, (err) => {
      this.maintenance = true;
    });

  }

  getCoverURL(type) {
    return this.sanitizer.bypassSecurityTrustStyle('url("' + environment.api.alpine.url + '/config/site/cover/' + type + '")');
  }

  getCoverURL2(type) {
    return this.sanitizer.bypassSecurityTrustUrl(environment.api.alpine.url + '/config/site/cover/' + type);
  }

}


@Component({
  selector: 'app-viewer-catalogs',
  templateUrl: './viewer-catalogs.component.html',
  styleUrls: ['./viewer-catalogs.component.css']
})
export class CatalogsDialog implements OnInit {

  selectedCalalog: any;

  currentIndx = 0;

  apiURL: string = environment.api.alpine.url;

  //noinspection JSAnnotator
  constructor(public dialogRef: MatDialogRef<CatalogsDialog>, @Inject(MAT_DIALOG_DATA) public catalogs: any, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.selectedCalalog = this.catalogs[this.currentIndx];
  }

  selectCatalog(id) {
    //noinspection TypeScriptUnresolvedFunction
    this.dialogRef.close(id);
  }

  next() {
    if (this.currentIndx === this.catalogs.length - 1) {
      this.currentIndx = -1;
    }
    this.selectedCalalog = this.catalogs[this.currentIndx + 1];
    this.currentIndx++;
  }

  prev() {
    if (this.currentIndx === 0) {
      this.currentIndx = this.catalogs.length;
    }
    this.selectedCalalog = this.catalogs[this.currentIndx - 1];
    this.currentIndx--;
  }

  getSelectedCatalogCover() {
    return this.sanitizer.bypassSecurityTrustStyle('url("' + environment.api.alpine.url + '/catalog/' + this.selectedCalalog.id + '/cover?size=800")');
  }
}

import {Serializable} from '../utils/serializable.util';
import {User} from './user.model';
import {Section} from './section.model';

export class Catalog extends Serializable {
  id: string;
  _id?: string;
  name: string;
  nomenclature: Section[];

}

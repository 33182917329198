import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TranslateModule , TranslateLoader} from '@ngx-translate/core';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';

import { AppComponent } from './app.component';

import {ViewerModule} from './viewer/viewer.module';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {CatalogsModule} from './catalogs/catalogs.module';
import { GlobalErrorHandler } from './globalerrorhandler';
import {DialogComponent} from './shared/dialog/dialog.component';
import {MatButtonModule, MatDialogModule} from '@angular/material';
import {TokenInterceptor} from './_interceptors/token.interceptor';
import {InitAccountModule} from './init-account/init-account.module';
import {AuthInterceptor} from './_interceptors/auth.interceptor';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import {TimestampToDatePipe} from './shared/pipes/timestamp-to-date.pipe';
import { LegalComponent } from './legal/legal.component';
import { CookieComponent } from './legal/cookie/cookie.component';
import { TermsComponent } from './legal/terms/terms.component';
import { PrivacyComponent } from './legal/privacy/privacy.component';
import { CookieBannerComponent } from './legal/cookie-banner/cookie-banner.component';
registerLocaleData(localeFr, 'fr');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    DialogComponent,
    TimestampToDatePipe,
    LegalComponent,
    CookieComponent,
    TermsComponent,
    PrivacyComponent,
    CookieBannerComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ViewerModule,
    CatalogsModule,
    InitAccountModule,
    RouterModule,
    AppRoutingModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'fr' }
  ],
  entryComponents: [
    DialogComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

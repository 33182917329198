import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../_services/order.service';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from '../../_services/dialog.service';

@Component({
  selector: 'app-viewer-history',
  templateUrl: './viewer-history.component.html',
  styleUrls: ['./viewer-history.component.css']
})
export class ViewerHistoryComponent implements OnInit {

  orders: any = [];

  selectedOrder: any;

  ORDER_STATUS_ICONS = ['hourglass_full', 'check_circle_outline', 'highlight_off', 'credit_card', 'credit_card', 'timer', 'local_shipping'];

  constructor(private orderService: OrderService , private translateService: TranslateService , private dialogService: DialogService) {
  }

  ngOnInit() {
   this.loadData();
  }
  loadData() {
    this.orderService.getOrders().subscribe((orders) => {
      this.orders = orders;
    });
  }

  onCancelOrder(event, order) {
    event.preventDefault();
    event.stopPropagation();
    const title = this.translateService.instant('viewer-history.cancel_order');
    const text = this.translateService.instant('viewer-history.cancel_order_message');
    const dialogRef = this.dialogService.modal(title, text, 'warn', true);
    dialogRef.afterClosed().subscribe((data) => {
      if (data === 'CONFIRM') {
        this.orderService.cancelOrder(order).subscribe(() => {
          this.loadData();
        });
      }
    });
  }

  getArticlesCount(order) {
    return order.content.map((article) => article.count).reduce((a, b) => a + b);
  }

  selectOrder(order) {
    this.selectedOrder = order;
  }

  back() {
    this.selectedOrder = null;
  }

}

import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient) { }

  getShopConfig() {
    return this.http.get<any>(environment.api.alpine.url + '/config/shop');
  }

  getCountriesConfig() {
    return this.http.get<any>(environment.api.alpine.url + '/config/countries');
  }
}

/************************
 ******* @ANGULAR
 ***********************/

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTreeModule
} from '@angular/material';
import {CatalogsComponent, CatalogsDialog} from './catalogs.component';
import {TranslateModule} from '@ngx-translate/core';

/************************
 ******* MODULE
 ***********************/

/************************
 ******* PROVIDERS
 ***********************/

/************************
 ******* COMPONENT
 ***********************/


@NgModule({
  declarations: [
    CatalogsComponent,
    CatalogsDialog,
  ],
  entryComponents: [
    CatalogsDialog
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTreeModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatTabsModule,
    FormsModule,
    MatSelectModule,
    MatRippleModule,
    TranslateModule
  ]
})
export class CatalogsModule {
}

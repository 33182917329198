export const environment = {
  production: false,
  shop: null,
  api : {
    alpine : {
      'url' : 'https://admin-test.renaultsport-racingparts.com/api',
      'jwt_client_id' : 'testjwtclientid',
      'jwt_client_secret' : 'XY7kmzoNzl100',
      'token' : null,
      'isGoogleTagManagerEnabled' : true,
      'googleTagManagerId' : 'G-DL6NZ0QW7N'
    }
  },
};

export class Serializable {

    assignFromObject<T>(_data: any, objectType: { new(): T; }): T {
      //noinspection TypeScriptUnresolvedFunction
      return Object.assign(new objectType(), _data);
    }

    assignFromObjectList<T>(_dataList: any[], objectType: { new(): T; }): T[] {
      const arrayList = new Array<T>();
      _dataList.forEach((data) => {
        //noinspection TypeScriptUnresolvedFunction
        arrayList.push(Object.assign(new objectType(), data));
      });

      return arrayList;
    }
}

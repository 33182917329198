import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '../../_services/cart.service';
import { ArticleService } from '../../_services/article.service';
import { Article } from '../../shared/model/article.model';
import { Item } from '../../shared/model/item.model';

@Component({
  selector: 'app-viewer-details',
  templateUrl: './viewer-details.component.html',
  styleUrls: ['./viewer-details.component.css']
})
export class ViewerDetailsComponent implements OnInit {
  @Input()
  set selectedItem(item) {
    this._selectedItem = item;
    this.selectedArticle = {
      ref: item.calque.ref,
      ref2: item.calque.ref2,
      unite_service: item.calque.unite_service,
      prix: item.calque.prix,
      stock: item.calque.stock,
      label: item.calque.label,
      label2: item.calque.label2,
      discounts: item.calque.discounts,
      code_bareme: item.calque.code_bareme,
      code_tva: item.calque.code_tva,
      f2marq: item.calque.f2marq,
      remise: item.calque.remise,
    };
    this.replacementArticle = null;
    if (item.calque.ref2 && item.calque.ref2.trim() && !item.calque.stock  ) {
      this.articleService.getArticle(this.selectedArticle.ref2).subscribe((article) => {
        this.replacementArticle = article;
      });
    }
  }

  get selectedItem(): Item {
    return this._selectedItem;
  }

  @Input() catalog;

  _selectedItem: Item;

  selectedArticle: Article;

  replacementArticle: Article;

  constructor(public snackBar: MatSnackBar,
    private cartService: CartService,
    public translateService: TranslateService,
    public articleService: ArticleService) {
  }

  ngOnInit() {}


  addToCart(shopMode) {
    this.cartService.addToCart(this.replacementArticle || this.selectedArticle);
    this.snackBar.open(this.translateService.instant(
      shopMode > 1 ? 'viewer-details.article_added_to_cart' : 'viewer-details.article_added_to_wishlist'), null, {
      duration: 2000,
    });

  }

  isFavorite() {
    return this.cartService.isItemFavorite(this.selectedArticle.ref);
  }

  toggleFavorite() {
    if (this.isFavorite()) {
      this.cartService.removeFromWishList(this.selectedArticle.ref);
    } else {
      this.cartService.addToWishList(this._selectedItem);
    }
  }

  loadArticle(ref) {
    if (this.selectedArticle.ref === ref) {
      return;
    }
    if (this.selectedItem.calque.ref === ref) {
      this.selectedArticle = {
        ref: this.selectedItem.calque.ref,
        ref2: this.selectedItem.calque.ref2,
        unite_service: this.selectedItem.calque.unite_service,
        prix: this.selectedItem.calque.prix,
        stock: this.selectedItem.calque.stock,
        label: this.selectedItem.calque.label,
        label2: this.selectedItem.calque.label2,
        discounts: this.selectedItem.calque.discounts,
        code_bareme: this.selectedItem.calque.code_bareme,
        code_tva: this.selectedItem.calque.code_tva,
        f2marq: this.selectedItem.calque.f2marq,
        remise: this.selectedItem.calque.remise
      };
      return;
    }
    const toSelect = this.selectedItem.calque.variantes.find(variante => variante.ref === ref);
    if (toSelect) {
      this.selectedArticle = toSelect;
    }
  }

  hasStockAvailable(): boolean {
    return !!((this.selectedArticle && this.selectedArticle.stock) || (this.replacementArticle && this.replacementArticle.stock));
  }

}

import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatStepper} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {LoginService} from '../../_services/login.service';
import {OrderService} from '../../_services/order.service';
import {CartService} from '../../_services/cart.service';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from '../../_services/dialog.service';

@Component({
  selector: 'app-viewer-purchase',
  templateUrl: './viewer-purchase.component.html',
  styleUrls: ['./viewer-purchase.component.css'],
})
export class ViewerPurchaseComponent implements OnInit {
  @ViewChild('stepper')
  stepper: MatStepper;

  showAddComment: boolean = false;
  file: any = null;
  comment: string = '';
  cart: any;

  catalog: any;

  // isLoading = false;

  constructor(
    private sanitizer: DomSanitizer,
    private loginService: LoginService,
    private orderService: OrderService,
    private cartService: CartService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef?: MatDialogRef<ViewerPurchaseComponent>) {
  }

  ngOnInit() {
    this.catalog = this.data.catalog;
    this.cart = this.cartService.getCart();

    this.loginService.loginSubject.subscribe(() => {
      this.stepper.next();
    });
  }

  nextStep(step) {
    const loggedUser = this.loginService.getLoggedUser();
    this.stepper.next();
    if (step === 'LOGIN' && loggedUser) {
      this.stepper.next();
    }
  }

  onFileSelect(e: any) {
    if (e.target.files.length > 0) {
      this.file = e.target.files[0];
    }
  }
  
  onShowAddComment(event: any) {
    event.preventDefault();
    this.showAddComment = true;
  }

  sendOrder() {
    // this.isLoading = false;
    const data = this.showAddComment ? {comment: this.comment,file: this.file} : {}
    this.orderService.sendOrder(data).subscribe((order) => {
      if (order) {
        this.cartService.cleanCart();
        this.stepper.next();
        this.cartService.cleanCart();
      }
      // this.isLoading = true;
    });
  }

  clearCart() {
    const title = this.translateService.instant('viewer-cart.clear_title');
    const text = this.translateService.instant('viewer-cart.clear_message');
    const dialogRef = this.dialogService.modal(title, text, 'warn', true);
    dialogRef.afterClosed().subscribe((data) => {
      if (data === 'CONFIRM') {
        this.cartService.cleanCart();
      }
    });
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs-compat/add/operator/map';
import {environment} from '../../environments/environment';
import {Catalog} from '../shared/model/catalog.model';
import {Observable} from 'rxjs';
import {Planche} from '../shared/model/planche.model';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  glossary: any = {};

  // @ts-ignore
  userLang = navigator.language || navigator.userLanguage;

  constructor(private http: HttpClient) {
    this.userLang = this.userLang.split('-')[0];
    this.userLang = this.userLang === 'fr' ? 1 : 2;
  }

  getCatalog(id): Observable<Catalog> {
    return this.http.get<Catalog>(environment.api.alpine.url + '/v1/catalog/' + id).map((catalog) => {
      this.http.get<any>(environment.api.alpine.url + '/catalog/' + id + '/glossary').subscribe((glossary) => {
        this.glossary = glossary;
      });
      return catalog;
    });
  }

  getAllCatalogs(catalogType): Observable<Catalog[]>  {
    return this.http.get<Catalog[]>(environment.api.alpine.url + '/catalog?type=' + catalogType);
  }

  getCatalogGraphic(cid, pid) {
    return this.http.get(environment.api.alpine.url + '/v1/catalog/' + cid + '/planche/' + pid + '/graphic/', {responseType: 'text'});
  }

  getArticle(pid) {
    return this.http.get(environment.api.alpine.url + '/article/' + pid).map((a: any) => {
      if (a.unite_service <= 0) {
        a.unite_service = 1;
      }
      return a;
    });
  }

  getGlossary(data) {
    const ref = data.id || data.ref;
    if (this.glossary[ref] && this.glossary[ref][this.userLang]) {
      return this.glossary[data.id][this.userLang];
    }
    return data.label.trim();
  }

  getPlancheDetails(catalogid: string, plancheid: string): Observable<Planche>  {
    let headers = new HttpHeaders({
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.http.get<Planche>(environment.api.alpine.url + '/v1/catalog/' + catalogid + '/planche/' + plancheid ,{headers :  headers});
  }

  findPlanchesByKeyword(catalogid: string, keyword: string) {
    return this.http.get(environment.api.alpine.url + '/V1/catalog/' + catalogid + '/planche/find?keyword=' + keyword );
  }

  findCalquesByKeyword(plancheid: string, keyword: string) {
    return this.http.get(environment.api.alpine.url + '/planche/' + plancheid + '/find?keyword=' + keyword );
  }
}

import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import { DialogService } from './_services/dialog.service';
import { Router,  } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  lastRoute = '';

  router: Router;

  errorStack = []

  constructor(public dialogService: DialogService, private ngZone: NgZone , private injector: Injector , private translateService : TranslateService) {
  }

  handleError(error) {

    // Ignore I18N HTTP errors
    const i18nRegexp = new RegExp("\\/assets\\/i18n" , "gm")
    if (error.name === "HttpErrorResponse" && i18nRegexp.test(error.url) ){
      return;
    }
    if (!this.router) {
      this.router = this.injector.get(Router)
      this.router.events.subscribe((route) => {
        this.errorStack = [];
      });
    }

    if (error && error.status === '403') {
      localStorage.removeItem('loggedUser');
      window.location.href = 'login';
      return;
    }else if (error && error.status == 400){
      this.ngZone.run(() => {
        this.dialogService.error(
          // @ts-ignore
          {message: this.translateService.instant( error.error)}
        )
      });
      // @ts-ignore
    }else{
      this.dialogService.error(
        {message:  this.translateService.instant("errors.message")}
      )
      console.error(error)
    }
  }

}


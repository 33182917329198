import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {LoginService} from '../../_services/login.service';
import {OrderService} from '../../_services/order.service';
import {DialogService} from '../../_services/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {CatalogService} from '../../_services/catalog.service';
import {CartService} from '../../_services/cart.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-viewer-cart',
  templateUrl: './viewer-cart.component.html',
  styleUrls: ['./viewer-cart.component.css']
})
export class ViewerCartComponent implements OnInit {

  config: any = {};

  @Input('withTotal')
  withTotal = false;

  cart: any;

  shopMode = environment.shop.mode;

  invoiceDetails = {
    total: 0,
    promo: 0,
    remise: 0,
    vat: 0,
    ttc: 0
  };

  constructor(
    private sanitizer: DomSanitizer,
    private loginService: LoginService,
    private orderService: OrderService,
    private catalogService: CatalogService,
    private cartService: CartService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.config = this.route.snapshot.data;

    this.cart = this.cartService.getCart();

    this.invoiceDetails = this.cartService.invoiceDetails;

    if (this.config.print) {
      window.print();
      return;
    }



  }

  removeItem(index) {
    const title = this.translateService.instant('viewer-cart.remove_from_cart_title');
    const text = this.translateService.instant('viewer-cart.remove_from_cart_message');
    const dialogRef = this.dialogService.modal(title, text, 'warn', true);
    dialogRef.afterClosed().subscribe((data) => {
      if (data === 'CONFIRM') {
        this.cartService.removeItem(index);
      }
    });
  }

  updateQuantity(index, count) {
    if ((this.cart[index].count + count) <= 0) {
      return this.removeItem(index);
    }
    this.cartService.updateQuantity(index, count);
  }

}

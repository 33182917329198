/** Flat node with expandable and level information */

export class TreeNode {

  get htmlNodeId() {
    if (this.item.ref) {
      return 'node-btn-' + this.item.ref;
    } else {
      return 'node-btn-' + this.item._id;
    }
  }

  public isLoading = false;

  public selected = false;

  public expanded = false;

  public hidden = false;

  public highlightedChildrenIndexes = [];

  public constructor(public item: any, public level: number, public expandable: boolean, public parent: TreeNode = null){ }

}

/************************
 ******* @ANGULAR
 ***********************/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SafePipe} from '../_pipes/safe.pipe';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatTreeModule,
  MatProgressBarModule,
  MatFormFieldModule,
  MatInputModule,
  MatToolbarModule,
  MatTabsModule,
  MatSelectModule, MatCheckboxModule, MatMenuModule, MatTableModule, MatStepperModule, MatRadioModule
} from '@angular/material';

/************************
 ******* MODULE
 ***********************/

/************************
 ******* PROVIDERS
 ***********************/

/************************
 ******* COMPONENT
 ***********************/

import { InitAccountComponent } from './init-account.component';
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [
    InitAccountComponent,
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTreeModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatTabsModule,
    FormsModule,
    MatSelectModule,
    MatMenuModule,
    MatTableModule,
    MatStepperModule,
    MatRadioModule,
    TranslateModule
  ]
})
export class InitAccountModule{ }

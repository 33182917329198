import { Component, Inject } from '@angular/core';
import {MatDialogRef , MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-my-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  //noinspection JSAnnotator
  constructor(public thisDialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  onCloseConfirm() {
    //noinspection TypeScriptUnresolvedFunction
    this.thisDialogRef.close('CONFIRM');
  }

  onCloseCancel() {
    this.data = 'CANCEL'
    //noinspection TypeScriptUnresolvedFunction
    this.thisDialogRef.close('CANCEL');
  }

  onClose() {
    //noinspection TypeScriptUnresolvedFunction
    this.thisDialogRef.close('CANCEL');
  }
}

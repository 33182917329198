import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs-compat/add/operator/map';
import {environment} from '../../environments/environment';
import {Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  loginSubject = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  login(email, password) {
    return this.http.post<any>(environment.api.alpine.url + '/login/', {email: email, password: password}).map((customer) => {
      localStorage.setItem('loggedUser', JSON.stringify(customer));
      this.loginSubject.next(true);
      return customer;
    });
  }

  logout() {
    localStorage.removeItem('loggedUser');
    this.loginSubject.next(false);
  }

  register(customer) {
    return this.http.post<any>(environment.api.alpine.url + '/customer', customer);
  }

  update(customer) {
    return this.http.put<any>(environment.api.alpine.url + '/customer', customer).map((updatedCustomer) => {
      const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
      loggedUser.user = updatedCustomer;
      localStorage.setItem('loggedUser', JSON.stringify(loggedUser));
      return loggedUser;
    });
  }

  getCustomerToken(tokenId) {
    return this.http.get<any>(environment.api.alpine.url + '/token/' + tokenId);
  }

  initPassword(tokenId, data) {
    return this.http.post<any>(environment.api.alpine.url + '/token/' + tokenId + '/password', data);
  }

  getLoggedUser() {
    let user = localStorage.getItem('loggedUser');
    if (user) {
      user = JSON.parse(user);
    }
    return user;
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {DynamicDataSource} from './dynamicdatasource.class';
import {Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CatalogService} from '../../_services/catalog.service';
import {Catalog} from '../../shared/model/catalog.model';
import {TreeNode} from './treenode.class';
import {Item, ItemType} from '../../shared/model/item.model';

declare const $;

@Component({
  selector: 'app-viewer-tree',
  templateUrl: './viewer-tree.component.html',
  styleUrls: ['./viewer-tree.component.css']
})
export class ViewerTreeComponent implements OnInit {

  sections = [];

  fullTextSearchEnabled = false;

  token = null;

  currentTreeId = 0;

  @Input()
  catalog: Catalog = null;

  selectedItem: Item = null;

  selectedNode = null;

  loading = true;

  switching = false;

  disableSectionTab = false;

  apiURL = environment.api.alpine.url;

  @Input() viewerSubject: Subject<Item>;

  @Input() breadcrum: string[];

  constructor(private catalogService: CatalogService) {
  }

  ngOnInit(): void {
    this.viewerSubject.asObservable().subscribe((item) => {
      if (item.root && item.hasOwnProperty('section')) {
          return this.loadRoot(item.section);
      }
      this.selectedItem = item;
      // If item has been selected from the graph, find the associated node, expand it and scroll to it in the tree
      if (item.fromGraph) {
        if (item.root) {
          return this.reset(() => {
            this.loadRoot(this.currentTreeId);
          });
        }
        // Select planche from graph
        if (!item.index) {
          const plancheNode = this.sections[this.currentTreeId].dataSource.data.find((e) => {
            return e.item._id === item.plancheId;
          });
          if (plancheNode.hidden) {
            this.reset(() => {
              this.selectedNode = plancheNode;
              this.updateBreadcrumb(plancheNode);
              this.toggleNode(plancheNode);
            });
            return;
          }
          this.selectedNode = plancheNode;
          this.updateBreadcrumb(plancheNode);
          this.toggleNode(plancheNode);
          return;
        }
        const planche = this.sections[this.currentTreeId].dataSource.data.find((e) => {
          return e.item._id === item.plancheId;
        });
        const selectCalque = () => {
          const treeNodes = this.sections[this.currentTreeId].dataSource.data.filter(e => {
            return e.parent && e.parent.item._id === item.plancheId;
          });
          const node = treeNodes.find(e => e.item.index == item.index);
          //console.log(node)
          if (!node) {
            return;
          }
          if (!node.item.ref) return
          this.selectedNode = node;
          this.updateBreadcrumb(node);
          this.selectedItem.calque = node.item;
          this.selectedItem.node_type = (node.item.variantes && node.item.variantes.length > 0) ? ItemType.ITEM_GROUP : ItemType.ITEM;
          const htmlNode = document.getElementById(node.htmlNodeId);
          document.getElementById('tree-section-' + this.currentTreeId).scrollTop = htmlNode.offsetTop - 200;
        };
        if (planche && !planche.expanded) {
          this.toggleNode(planche, () => {
            selectCalque();
          });
        } else {
          selectCalque();
        }
      }
    });

    this.catalog.nomenclature.forEach((section, i) => {
      const treeControl = new FlatTreeControl<TreeNode>((node: TreeNode) => node.level, (node: TreeNode) => node.expandable);
      const dataSource = new DynamicDataSource(treeControl, this.catalog.id, section, this.catalogService);
      this.sections.push({dataSource: dataSource, treeControl: treeControl, label: section.label});
    });
    this.loadRoot(this.currentTreeId);
    this.loading = false;
  }

  reset(cbk) {
    this.token = null;
    this.fullTextSearchEnabled = false;
    const next = (i) => {
      if (i >= this.sections.length) {
        return cbk();
      }
      this.sections[i].dataSource.reset(() => {
        next(i + 1);
      });
    };
    next(0);
  }

  selectPlancheNode(node: TreeNode) {
    this.selectedNode = node;
    this.updateBreadcrumb(node);
    this.toggleNode(node);
    this.viewerSubject.next({plancheId: node.item._id});
  }

  selectCalqueNode(node: TreeNode) {
    this.selectedNode = node;
    this.updateBreadcrumb(node);
    this.viewerSubject.next({
      plancheId: node.parent.item._id,
      calque: node.item,
      index: node.item.index,
      node_type: node.item.variantes.length === 0 ? ItemType.ITEM : ItemType.ITEM_GROUP,
    });
  }

  switchingDone() {
    this.switching = false;
  }

  fullTextSearch(value: string) {
    this.disableSectionTab = true;
    let searchSectionsDone = 0;
    this.sections.forEach((section, i) => {
      section.dataSource.reset(() => {
        if (!value || value.trim() === '') {

          return;
        }
        section.dataSource.searchByPlanche(value, (firstNode) => {
          searchSectionsDone++;
          if (searchSectionsDone === this.sections.length) {
            this.disableSectionTab = false;
          }
          if (i === this.currentTreeId) {
            if (!firstNode) {
              this.viewerSubject.next({root: true});
              return;
            }
            this.selectedNode = firstNode;
            this.updateBreadcrumb(firstNode);
            this.viewerSubject.next({plancheId: firstNode.item._id});
          }
        });
      });
    });
  }

  loadRoot(treeId) {
    this.sections[this.currentTreeId].dataSource.collapseAll();
    this.selectedNode = null;
    this.selectedItem = null;
    this.currentTreeId = treeId;
    this.updateBreadcrumb();
    if (this.fullTextSearchEnabled && this.token) {
      this.disableSectionTab = true;
      this.sections[treeId].dataSource.expandAll(() => {
        this.disableSectionTab = false;
      });
    }
  }

  switchSection(sectionNumber: number) {
    if (this.switching) {
      return;
    }
    this.viewerSubject.next({
      root: true,
      section: sectionNumber
    });
  }


  hasChild = (_: number, _node: TreeNode) => {
    return _node.expandable;
  };

  getPictoURL(node: any) {
    return this.apiURL + '/config/picto/' + node.item.picto;
  }

  toggleNode(node: TreeNode, cbk = null) {
    this.sections[this.currentTreeId].dataSource.toggleNode(node, null, cbk);
  }

  updateBreadcrumb(node: TreeNode = null) {
    this.breadcrum.splice(0, this.breadcrum.length);
    this.breadcrum.push(this.sections[this.currentTreeId].label);
    while (node) {
      const label = node.item.label || node.item.ref;
      this.breadcrum.splice(1, 0, label);
      node = node.parent;
    }
  }
}

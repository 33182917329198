/************************
 ******* @ANGULAR
 ***********************/

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SafePipe} from '../_pipes/safe.pipe';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatTreeModule,
  MatProgressBarModule,
  MatFormFieldModule,
  MatInputModule,
  MatToolbarModule,
  MatTabsModule,
  MatSelectModule, MatCheckboxModule, MatMenuModule, MatTableModule, MatStepperModule, MatRadioModule, MatTooltipModule
} from '@angular/material';
import {ViewerComponent} from './viewer.component';
import {ViewerTreeComponent} from './viewer-tree/viewer-tree.component';
import {ViewerGraphComponent} from './viewer-graph/viewer-graph.component';
import {ViewerDetailsComponent} from './viewer-details/viewer-details.component';
import {ViewerCartComponent} from './viewer-cart/viewer-cart.component';
import {ViewerLoginComponent} from './viewer-login/viewer-login.component';
import {ViewerHistoryComponent} from './viewer-history/viewer-history.component';
import {TranslateModule} from '@ngx-translate/core';
import {GlossaryPipe} from '../_pipes/glossary.pipe';
import {ViewerPurchaseComponent} from './viewer-purchase/viewer-purchase.component';
import {ViewerWishlistComponent} from './viewer-wishlist/viewer-wishlist.component';

/************************
 ******* MODULE
 ***********************/

/************************
 ******* PROVIDERS
 ***********************/

/************************
 ******* COMPONENT
 ***********************/

@NgModule({
  declarations: [
    ViewerComponent,
    ViewerTreeComponent,
    ViewerGraphComponent,
    ViewerDetailsComponent,
    SafePipe,
    GlossaryPipe,
    ViewerCartComponent,
    ViewerLoginComponent,
    ViewerHistoryComponent,
    ViewerPurchaseComponent,
    ViewerWishlistComponent
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTreeModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatTabsModule,
    MatTooltipModule,
    FormsModule,
    MatSelectModule,
    MatMenuModule,
    MatTableModule,
    MatStepperModule,
    MatRadioModule,
    TranslateModule
  ],
  entryComponents: [
    ViewerPurchaseComponent,
    ViewerHistoryComponent,
    ViewerLoginComponent
  ]
})
export class ViewerModule {
}

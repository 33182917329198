import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs-compat/add/operator/map';
import {environment} from '../../environments/environment';
import {CartService} from './cart.service';


@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient, private cartService: CartService) {
  }

  sendOrder(data: any) {
    const cart = this.cartService.getCart();
    const orderContent = [];
    cart.forEach((item) => {
      if (!(item.article.hasOwnProperty('prix') && item.article.prix != null)) {
        return;
      }
      const data = {
        label: item.article.label,
        ref: item.article.ref,
        count: item.count
      };
      orderContent.push(data);
    });
    const formData = new FormData();
    formData.append('cart', JSON.stringify(orderContent));
    if (data.comment) formData.append('comment', data.comment);
    if (data.file) formData.append('file', data.file);
    return this.http.post<any>(environment.api.alpine.url + '/order/', formData);
  }

  getOrders() {
    return this.http.get<any>(environment.api.alpine.url + '/order');
  }

  cancelOrder(order) {
    return this.http.delete<any>(environment.api.alpine.url + '/order/' + order.id);
  }

}

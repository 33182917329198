import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  injectGtmScript() {
    if (environment.api.alpine.isGoogleTagManagerEnabled) {
        const gtmScript = this.document.createElement('script');
        gtmScript.type = 'text/javascript';
        gtmScript.async = true;
        gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + environment.api.alpine.googleTagManagerId;
        
        const dataLayerScript = this.document.createElement('script');
        dataLayerScript.innerText = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${environment.api.alpine.googleTagManagerId}');
        `;
        this.document.head.appendChild(dataLayerScript);
        this.document.head.appendChild(gtmScript);
    }
  }

  isInjected(): boolean {
    return this.document.head.querySelector('script[src="https://www.googletagmanager.com/gtm.js?id=G-DL6NZ0QW7N"]') !== null;
  }
}
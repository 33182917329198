import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';

import {CatalogService} from '../_services/catalog.service';
import {MatDialog, MatSidenav, MatSnackBar} from '@angular/material';
import {MediaMatcher} from '@angular/cdk/layout';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {ViewerLoginComponent} from './viewer-login/viewer-login.component';
import {LoginService} from '../_services/login.service';
import {ViewerHistoryComponent} from './viewer-history/viewer-history.component';
import {TranslateService} from '@ngx-translate/core';
import {ViewerPurchaseComponent} from './viewer-purchase/viewer-purchase.component';
import {ViewerWishlistComponent} from './viewer-wishlist/viewer-wishlist.component';
import {CartService} from '../_services/cart.service';
import { Title } from '@angular/platform-browser';
import { GtmService } from '../_services/gtm.service';
declare let gtag: Function;
@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css']
})

export class ViewerComponent implements OnInit {

  isAdmin = environment.api.alpine.token;

  loggedUser: any;

  catalog: any;

  selectedItem: any;

  coverurl: any;

  @ViewChild('rightDrawer') rightDrawer: MatSidenav;

  @ViewChild('leftDrawer') leftDrawer: MatSidenav;

  mobileQuery: MediaQueryList;

  _mobileQueryListener: () => void;

  viewerSubject: Subject<any> = new Subject();

  breadcrumb: string[] = [];

  constructor(private sanitizer: DomSanitizer,
              private catalogService: CatalogService,
              private cartService: CartService,
              private changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private route: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              public loginService: LoginService,
              public translateService: TranslateService,
              public snackBar: MatSnackBar,
              private titleService: Title,
              private gtmService: GtmService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.loggedUser = this.loginService.getLoggedUser();

    this.loginService.loginSubject.subscribe((connected) => {
      if (connected) {
        this.loggedUser = this.loginService.getLoggedUser();
      }
    });

    this.loginService.loginSubject.subscribe(() => {
      this.loggedUser = this.loginService.getLoggedUser();
    });

    const catalogId = this.route.snapshot.paramMap.get('id');
    this.catalogService.getCatalog(catalogId).subscribe((catalog) => {
      this.coverurl = this.sanitizer
        .bypassSecurityTrustStyle('url(' + environment.api.alpine.url + '/catalog/' + catalog.id + '/cover?size=400)');
      this.catalog = catalog;
      this.titleService.setTitle((this.catalog.type.charAt(0).toUpperCase() + this.catalog.type.slice(1)) + " - " + this.catalog.name);
      if (this.gtmService.isInjected()){
        gtag('event', 'page_view', {
        'page_path': '/catalog/' + this.catalog.id
        });
      }
      
    });

    this.viewerSubject.subscribe((item) => {
      this.selectItem(item);
    });
  }

  selectCatalog() {
    this.router.navigateByUrl('/');
  }

  selectItem(item) {
    this.selectedItem = item;
    if (this.rightDrawer) {
      this.rightDrawer.open().then(() => {
      }).catch((err) => {
        console.error(err);
      });
    }
    if (this.leftDrawer && this.mobileQuery.matches) {
      this.leftDrawer.close();
    }
    for (let i = 0; i < this.catalog.nomenclature.length; i++) 
      for (let j = 0; j < this.catalog.nomenclature[i].children.length; j++) 
        if (this.catalog.nomenclature[i].children[j]._id === item.plancheId) {
          console.log("planche")
          console.log(this.catalog.nomenclature[i].children[j])
          const planche = this.catalog.nomenclature[i].children[j]
          this.titleService.setTitle((this.catalog.type.charAt(0).toUpperCase() + this.catalog.type.slice(1)) + " - " + this.catalog.name + " - " + planche.zone + '.' + planche.planche + ' - ' + planche.label);
          if (environment.api.alpine.isGoogleTagManagerEnabled){
            gtag('event', 'page_view', {
              'page_path': '/catalog/' + this.catalog.id + '/item/' + item.plancheId,
            });
          }
        } 
  }

  getCartCount() {
    return this.cartService.getNumberOfArticle();
  }

  getWishListCount() {
    return this.cartService.getNumberOfWishListArticle();
  }

  openCart() {
    this.dialog.open(ViewerPurchaseComponent, {
      width: '600px',
      maxWidth: '100vw',
      data: {
        catalog: this.catalog
      },
    });
  }

  openWishList() {
    const dialogref = this.dialog.open(ViewerWishlistComponent, {
      width: '600px',
      maxWidth: '100vw',
    });
    dialogref.beforeClose().subscribe((res) => {
      if (res) {
        this.selectItem(res);
      }
    });
  }

  openHistory() {
    this.dialog.open(ViewerHistoryComponent, {
      width: '600px',
      maxWidth: '100vw',
    });
  }

  editUser() {
    this.dialog.open(ViewerLoginComponent, {
      width: '600px',
      maxWidth: '100vw',
      data: {edit: true}
    });
  }

  login() {
    const dialogRef = this.dialog.open(ViewerLoginComponent, {
      width: '600px',
      maxWidth: '100vw',
      data: {edit: false}
    });
    dialogRef.afterClosed().subscribe((customer) => {
      this.loggedUser = this.loginService.getLoggedUser();
    });
  }

  logout() {
    this.snackBar.open(this.translateService.instant('viewer-login.logout_successful'), null, {duration: 1500});
    this.loginService.logout();
    this.loggedUser = null;
    location.reload()
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {CatalogService} from '../_services/catalog.service';

@Pipe({
  name: 'glossary'
})
export class GlossaryPipe implements PipeTransform {


  // @ts-ignore
  userLang = navigator.language || navigator.userLanguage;

  constructor(private sanitizer: DomSanitizer , private catalogService: CatalogService) {
    this.userLang = this.userLang.split('-')[0];
    this.userLang = this.userLang === 'fr' ? 1 : 2;
  }

  transform(data) {
    const ref = data.id || data.ref;
    if (this.catalogService.glossary[ref] && this.catalogService.glossary[ref][this.userLang]) {
      return this.catalogService.glossary[data.id][this.userLang];
    }
    return '<span class="not-translated">' + data.label.trim() + '</span>';
  }

}

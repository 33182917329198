import {Calque} from './calque.model';

export class Item {
  node_type?: ItemType;
  fromGraph?: boolean;
  index?: number;
  calque?: Calque;
  plancheId?: string;
  root?: boolean;
  section?: number;
}

export enum ItemType {
  ITEM_GROUP = 'item_group',
  ITEM = 'item'
}

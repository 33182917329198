import {Component, Inject, OnInit} from '@angular/core';
import {LoginService} from '../../_services/login.service';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from '../../_services/dialog.service';
import {ConfigService} from '../../_services/config.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-viewer-login',
  templateUrl: './viewer-login.component.html',
  styleUrls: ['./viewer-login.component.css']
})
export class ViewerLoginComponent implements OnInit {

  constructor(private loginService: LoginService,
              public dialogRef: MatDialogRef<ViewerLoginComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public snackBar: MatSnackBar,
              public translateService: TranslateService,
              public dialogService: DialogService,
              public configService: ConfigService) {
  }

  username: string;

  password: string;

  hasAccount = true;

  customer: any = {customer_type: 'PROFESSIONAL', country: 'FR'};

  countries: any;

  savedSuccessfully = false;

  displayRegistration = environment.shop.shop_register;

  ngOnInit() {
    this.configService.getCountriesConfig().subscribe((countries) => {
      this.countries = countries;
    });
    if (this.data.edit) {
      const loggedUser = this.loginService.getLoggedUser();
      // @ts-ignore
      this.customer = loggedUser.user;
      this.hasAccount = false;
    }
  }

  login() {
    this.loginService.login(this.username, this.password).subscribe((customer) => {
      this.snackBar.open(this.translateService.instant('viewer-login.login_successful'), null, {duration: 1500});
      if (!this.data.catalog) {
        this.dialogRef.close(customer);
      }
    }, (err) => {
      if (err.status === 401 || err.status === 403) {
        const title = this.translateService.instant('viewer-login.account_not_found_title');
        const message = this.translateService.instant('viewer-login.account_not_found_message');
        this.dialogService.modal(title, message, 'warn');
      } else {
        throw err;
      }
    });
  }

  register() {
    if (this.data.edit) {
      if (this.customer.password !== this.customer.password_confirm) {
        const title = this.translateService.instant('viewer-login.password_mismatch_title');
        const message = this.translateService.instant('viewer-login.password_mismatch_message');
        this.dialogService.modal(title, message, 'warn');
        return;
      }
      this.loginService.update(this.customer).subscribe(() => {
        this.savedSuccessfully = true;
        this.snackBar.open(this.translateService.instant('viewer-login.account_updated'), null, {duration: 1500});
      });
    } else if (this.displayRegistration){
      const cart = localStorage.getItem('cart');
      this.customer.cart = cart;
      this.loginService.register(this.customer).subscribe(() => {
        this.dialogRef.close();
        const title = this.translateService.instant('viewer-login.account_created_title');
        const message = this.translateService.instant('viewer-login.account_created_message');
        this.dialogService.modal(title, message, 'primary');
      }, (err) => {
        this.savedSuccessfully = false;
        if (err.error.mail && err.error.mail.indexOf('E_UNIQUE') >= 0) {
          const title = this.translateService.instant('viewer-login.account_exists_title');
          const message = this.translateService.instant('viewer-login.account_exists_message');
          this.dialogService.modal(title, message, 'warn');
        } else {
          throw err;
        }

      });
    }
  }

  changeType(customerType) {
    this.customer = {customer_type: customerType};
  }

  close() {
    if (!this.data.edit) {
      this.hasAccount = true;
      return;
    }
    this.dialogRef.close();
  }


}
